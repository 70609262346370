import { User } from "@dvs/auth";
import { Logo, LogoType } from "@dvs/ui";
import { classNames } from "@dvs/utils";
import Link from "next/link";

type Props = { sticky?: boolean; participant?: User };
export const Header = ({ sticky: isSticky, participant }: Props) => {
  return (
    <header
      className={classNames(
        // "top-0",
        // isSticky ? "" : "fixed z-[10]",
        "flex flex-col items-center justify-center p-5 w-full"
      )}
    >
      <div className={classNames("flex justify-center items-center")}>
        <Link href={"/"} passHref>
          <a
            className={classNames("block text-center space-y-2 w-[144px]")}
            // ref={logoButton}
            title="Home"
            aria-label="Home"
          >
            <Logo className={classNames("inline-flex")} />
            <LogoType
              //   ref={logoTypeRef}
              className={
                classNames("")
                // isMenuOpen && "opacity-100"
              }
            />
          </a>
        </Link>
      </div>
      <div
        className={classNames("text-center", "pt-3 space-y-2 relative -z-50")}
      >
        <h1
          className={classNames(
            "text-blue-400 font-serif",
            "text-7xl tracking-wider"
          )}
        >
          IMAGINE
        </h1>
        <p
          className={classNames(
            "text-blue-400 uppercase tracking-widest font-[Raleway] font-light leading-tight",
            "md:text-xl text-lg"
          )}
        >
          Concurs de arhitectură / Ediția a II-a
        </p>
      </div>
    </header>
  );
};
